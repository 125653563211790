import React from 'react';
import SEO from "../../components/seo"
import Layout from "../../components/layout"

import Grid from '@material-ui/core/Grid';
import Mission from '../../components/Mission'
import ServicesMenu from '../../components/ServicesMenu'
import SliderBootstrapStatic from '../../components/SliderBootstrapStatic';

export default function RegulatoryAffairs(){

    return(
        <Layout>
            <SEO 
                title="Regulatory Affairs"
                description="We help our clients seamlessly enter the Nigerian market by advising on the documentation required to get regulatory approvals."
            />
            <br /><br />
            <SliderBootstrapStatic title="Regulatory Affairs" />

            <div className="container">
                <br />
                <Grid container spacing={3}>
                        <Grid item xs={12} md={8} lg={8}>
                            <ServicesMenu />
                            <h5>Regulatory Services</h5>
                            <p className="pr-20 justify" style={{textAlign: 'justify'}}>
                                We help our clients seamlessly enter the Nigerian market by advising on the documentation required to get regulatory approvals. Dossier compilation, document filing and follow through with the regulatory agency to ensure product registration and market entry. We are also able to act as Regulatory agents and Marketing Authorization Holder for companies requiring such service throughout the entire life cycle of the product.
                            </p>
                        </Grid>
                        <Grid item xs={12} md={4} lg={4}>  
                                <Mission />
                        </Grid>
                    </Grid>
                    <br /> <br />
            </div>
        </Layout>
    );
}